import styled from "styled-components";

export const CartItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 15px;
`;

export const ItemImage = styled.img`
  width: 30%;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const AdjustContainer = styled.div`
  display: flex;
`;

export const AdjustButton = styled.div`
  align-self: center;
  cursor: pointer;
`;

export const RemoveButton = styled.div`
  font-size: 9px;
  cursor: pointer;
`;

export const ItemDetails = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px;
  gap: 2px;
  .item-name {
    font-size: 16px;
  }
`;
